<template>
  <div>
    <merchantMessage />
  </div>
</template>

<script>
import merchantMessage from "./merchantMessage.vue";
export default {
  name: "changmerchantMessage",
  components: { merchantMessage },
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
</style>